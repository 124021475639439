@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
:root {
  --bg-color: #1e1d21;
  --p-text-color: #666666;
  --title-text-color: #aaaaaa;
  --layer-color: #262529;
  --layer-color-w-transparency: rgb(38 37 41 0);
  --li-hover: #2f2f33;
  --blue-color: #5851db;
  --sidebar-color: #262529;
  --table-border: #2f2e33;
  --jobsquare_image_bg: #fff;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-drag: none;
}

::selection {
  background-color: #5851db62;
  opacity: 0.3;
}

.App {
  margin-top: 50px;
}

body {
  font-family: "Poppins", sans-serif;
}

html {
  background-color: var(--bg-color);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

a {
  text-decoration: none;
}

.detail-bar__title,
.homepageEvents__title,
.line-chart__title,
.state__title,
.state-graph__name,
.modal__title {
  font-size: 22px;
  font-weight: 500;
  color: var(--title-text-color);
  margin-bottom: 10px;
  margin-top: 0;
}

.map__iframe-container,
.stat,
.chart__block,
.state-graphs,
.state,
.location,
.share-module,
.tips,
.donate__info,
.modal,
.idCard,
.eventCard {
  background-color: var(--layer-color);
  border-radius: 7px;
  overflow: hidden;
  padding: 20px;
  -webkit-box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
}

.mobilenavbar {
  display: none;
}

.main-toggle {
  position: relative;
}

.main-toggle ul {
  margin: 0;
  padding: 0;
}

.main-toggle__el {
  -webkit-box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  /*don't edit. component style*/
  cursor: pointer;
  line-height: 40px;
  padding: 0 15px;
  background-color: var(--bg-color);
  /*don't edit, component style*/
  color: var(--title-text-color);
  height: 40px;
  width: 180px;
  border-radius: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /*don't edit, component style*/
  list-style-type: none;
  position: relative;
  /*don't edit. component style*/
}

.main-toggle__el::after {
  content: " ";
  position: absolute;
  right: 12px;
  top: 1px;
  /*don't edit. component style*/
  height: 36px;
  width: 36px;
  background-image: url("./components/icons/expand-arrow-light.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.main-toggle__tag {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: #5851db;
}

.main-toggle__el-container {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1;
  display: none;
  overflow: hidden;
  border-radius: 15px;
  -webkit-box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
}

.main-toggle__el-container .main-toggle__el {
  border-radius: 0;
}

.main-toggle__el-container .main-toggle__el:hover {
  background-color: var(--layer-color);
}

.main-toggle__el-container .main-toggle__el::after {
  display: none;
}

.button {
  padding: 10px 30px;
  display: inline-block;
  border: 2px solid var(--blue-color);
  border-radius: 7px;
  font-weight: 500;
  color: var(--blue-color);
  cursor: pointer;
}

.button:hover {
  background-color: var(--bg-color);
}

.button.twitter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #254064;
  color: white;
  border: 1px solid #3590ff;
  display: block;
  height: 48px;
  line-height: 28px;
  font-size: 14px;
  text-decoration: none;
  position: relative;
}

.button.twitter svg {
  width: 24px;
  margin-right: 20px;
  position: absolute;
  top: 12px;
}

.button.twitter svg path {
  fill: #3590ff;
}

.button.twitter p {
  text-align: center;
}

.button.twitter:hover {
  background-color: #3590ff;
}

.button.twitter:hover svg path {
  fill: #ffffff;
}

.button.moved-up {
  display: block;
  text-align: center;
}

.button.moved-up:hover {
  background-color: #5851db;
  color: white;
}

.button.disabled {
  background-color: #777777;
  pointer-events: none;
}

.button.jobApply {
  padding: 8px 10px;
  border-radius: 4px;
  background-color: var(--blue-color);
  color: white;
}

.button.jobApply .job-button-text {
  color: white;
  text-align: center;
}

.button.jobApply:hover {
  background-color: var(--bg-color);
  color: #5851db;
}

.button-location {
  height: 40px;
  line-height: 41px;
  width: 200px;
  text-align: center;
  border-radius: 5px;
  background-color: var(--blue-color);
  display: inline-block;
  color: white;
  margin-top: 10px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
}

.button-location:hover {
  background-color: #1e1d21;
  border: 1px solid var(--blue-color);
}

.button-location.loading::after {
  content: "";
  width: 14px;
  height: 14px;
  border: 3px solid #1e1d21;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 10px;
  left: 8px;
  border-top-color: #fff;
  -webkit-animation: loading 1s infinite;
  animation: loading 1s infinite;
  -webkit-animation-timing-function: linear;
}

.button-location.disabled {
  background-color: #777777;
  pointer-events: none;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

.sidebar {
  width: 240px;
  background-color: var(--sidebar-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  position: fixed;
  height: calc(100vh - 50px);
  top: 50px;
  left: 0;
  z-index: 6;
  -webkit-box-shadow: -12px 12px 20px 0 rgba(0, 0, 0, 0.16);
  box-shadow: -12px 12px 20px 0 rgba(0, 0, 0, 0.16);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
}

.sidebar a {
  text-decoration: none;
  color: var(--title-text-color);
}

.sidebar__nav-items-container {
  padding: 0;
  margin: 0;
}

.sidebar__nav-items {
  list-style-type: none;
  height: 40px;
  color: var(--title-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
}

.sidebar__nav-items:hover,
.sidebar__nav-items.active {
  background-color: var(--li-hover);
}

.sidebar__nav-items span {
  padding-left: 15px;
}

.sidebar__nav-items .cls-2,
.sidebar__nav-items .cls-3 {
  fill: var(--title-text-color);
}

.sidebar__nav-items .cls-1 {
  fill: var(--p-text-color);
}

.sidebar__nav-items.active .cls-1,
.sidebar__nav-items.active .cls-2 {
  fill: #5851db;
}

.sidebar__nav-items.active .cls-1 {
  opacity: 0.35;
}

.sidebar__nav-items.active .cls-3 {
  fill: #e1306c;
}

.brand {
  padding: 30px;
  border-bottom: 3px solid var(--bg-color);
}

.brand__logo {
  text-align: center;
  margin-bottom: 9px;
}

.brand__name {
  color: var(--title-text-color);
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.topBar {
  height: 50px;
  width: 100%;
  padding: 7px 0;
  background-color: var(--sidebar-color);
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  z-index: 3;
}

.topBar svg.smallLogo {
  height: 36px;
  width: 36px;
  display: block;
  margin: auto;
}

.topBar .button__share {
  display: none;
}

.menu-button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: fixed;
  top: 5px;
  left: 10px;
  z-index: 7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}

.menu-button:hover {
  background-color: var(--title-text-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.menu-button:hover svg {
  fill: var(--bg-color);
}

.menu-button svg {
  height: 26px;
  width: 26px;
  margin-left: 7px;
  margin-top: 7px;
  fill: var(--title-text-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.logo-path {
  fill: #fff;
  stroke: #fff;
}

.preference {
  padding: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 16px;
}

.preference__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.preference__item__text {
  font-size: 14px;
  color: var(--p-text-color);
}

.toggle {
  height: 14px;
  width: 28px;
  display: inline-block;
  position: relative;
  margin-top: -7px;
  cursor: pointer;
}

.toggle__slider {
  content: " ";
  background-color: #505051;
  border-radius: 7px;
  height: 14px;
  width: 28px;
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
}

.toggle__slider::before {
  content: " ";
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: #aaaaaa;
  position: absolute;
  left: 2px;
  top: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle__checkbox {
  opacity: 0;
  width: 28px;
  height: 14px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin: 0;
  cursor: pointer;
}

.toggle__checkbox:checked + .toggle__slider {
  background-color: #5851db;
}

.toggle__checkbox:checked + .toggle__slider::before {
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle__checkbox:checked ~ label {
  background-color: #5851db;
}

.feedback {
  padding: 10px 30px;
  border-top: 3px solid var(--bg-color);
}

.feedback__title {
  color: var(--p-text-color);
  font-size: 14px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.feedback__title img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.feedback .button {
  margin: auto;
}

.feedback .button.donate {
  background-color: #5851db;
  color: white;
  font-size: 14px;
}

.feedback .button__share {
  display: none;
}

.main-content {
  background-color: var(--bg-color);
  padding: 25px 30px 3px 270px;
  /*padding-top:75 if notification bar is included*/
  /*padding-left should be 30px + 240px for leaving space for sidebar*/
  max-width: 1440px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.detail-bar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.detail-bar__title-container {
  display: -ms-inline-grid;
  display: inline-grid;
  -ms-grid-columns: auto 90px;
  grid-template-columns: auto 90px;
}

.detail-bar__title {
  margin-right: 10px;
}

.detail-bar .main-toggle {
  top: -2px;
}

.detail-bar .main-toggle__el {
  background-color: var(--sidebar-color);
  color: var(--title-text-color);
  left: 0;
  top: 0px;
  width: 114px;
}

.detail-bar .main-toggle__tag-img {
  width: 24px;
  height: 24px;
  border-radius: 10px;
  margin-left: -4px;
  margin-right: 6px;
}

.detail-bar__last-update {
  color: var(--p-text-color);
}

.map-stat-container {
  margin-top: 16px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 8fr 4fr;
  grid-template-columns: 8fr 4fr;
  grid-gap: 30px;
  position: relative;
}

.map {
  height: 602px;
}

.map__message {
  color: var(--p-text-color);
  height: 30px;
}

.map__iframe-container {
  height: 100%;
  padding: 0;
}

.map__iframe-container iframe {
  height: 100%;
  width: 100%;
  border: 0;
}

.homepageEvents {
  margin: 30px 0;
  position: relative;
}

.homepageEvents__title {
  color: var(--title-text-color);
}

.homepageEvents__link {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--blue-color);
}

.hr-events-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(320px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 30px;
}

#india-last-update {
  position: absolute;
  color: var(--p-text-color);
  top: -40px;
  right: 0;
}

.stats-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  height: 126px;
}

.stat__title {
  color: var(--title-text-color);
  font-weight: 500;
}

.stat__figure {
  font-size: 42px;
  font-weight: 500;
}

.stat__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.stat__block__title {
  color: var(--title-text-color);
  font-weight: 500;
}

.stat__block__figure {
  padding-left: 5px;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}

.stat__daily-rise {
  font-size: 15px;
  margin-top: 22px;
  margin-left: 6px;
}

.stat-inliner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.line-chart__details-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.chart {
  position: relative;
}

.chart__message {
  color: var(--p-text-color);
  height: 30px;
  margin-bottom: 30px;
}

.chart__block {
  margin-top: 30px;
}

.chart__title {
  color: var(--title-text-color);
  font-weight: 500;
  margin-bottom: 20px;
}

.chart__graph-container {
  height: 300px;
}

.chart .main-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
}

.recovered-chart-daily,
.death-chart-daily,
.recovered-chart,
.death-chart {
  display: none;
}

.state {
  margin-top: 30px;
}

.state__title.big {
  font-size: 26px;
  font-weight: bold;
}

.state__table-container {
  overflow-x: hidden;
}

.state table {
  width: 100%;
}

.state thead {
  display: block;
  background-color: var(--layer-color);
  position: relative;
}

.state thead tr:first-child {
  border: 0;
}

.state tbody {
  display: block;
}

.state tr {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.5fr 1fr 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  /*separate grid columsn for district below*/
  border-bottom: thin solid var(--table-border);
  min-height: 40px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.state tr th,
.state tr td {
  text-align: right;
  padding-right: 10px;
  word-break: break-all;
}

.state tr th:first-child,
.state tr td:first-child {
  text-align: left;
}

.state tr td:first-child,
.state tr th:first-child {
  padding-left: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.state tr:hover {
  background-color: var(--bg-color);
}

.state.district tr {
  -ms-grid-columns: 1.7fr 1fr 1fr 1fr 0.8fr;
  grid-template-columns: 1.7fr 1fr 1fr 1fr 0.8fr;
}

.state th {
  color: var(--title-text-color);
  text-align: left;
  border-bottom: 1px solid var(--title-text-color);
  text-transform: uppercase;
}

.state td {
  color: var(--p-text-color);
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 8px 0;
}

.state__sort-button {
  height: 40px;
  width: auto;
  display: inline-block;
  background-color: var(--bg-color);
  color: var(--p-text-color);
  border-radius: 7px;
  margin: 5px 0 10px 0;
  line-height: 40px;
  padding: 0 25px;
  cursor: pointer;
}

.state__sort-button:hover {
  color: var(--title-text-color);
}

.location {
  position: relative;
}

.location__title {
  font-size: 16px;
  font-weight: 500;
  color: var(--title-text-color);
}

.location__message {
  color: var(--p-text-color);
  margin-top: 8px;
}

.location__text {
  font-size: 14px;
}

.location__text span {
  color: var(--p-text-color);
}

.share-module-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
}

.share-module {
  position: relative;
}

.share-module__title {
  font-size: 16px;
  font-weight: 500;
  color: var(--title-text-color);
}

footer {
  min-height: 60px;
  margin-top: 30px;
  padding: 20px 0;
  background-color: var(--bg-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--p-text-color);
  border-top: 3px solid var(--layer-color);
}

footer p a {
  color: #5851db;
  text-decoration: none;
}

footer p img {
  vertical-align: middle;
  margin: 0 5px 0 5px;
}

/*for preventive measures page*/
.tips {
  min-height: 500px;
}

.tips__block {
  margin-bottom: 30px;
}

.tips__title {
  font-size: 22px;
  font-weight: 500;
  color: var(--title-text-color);
  margin-bottom: 6px;
  font-weight: 500;
}

.tips__text {
  font-size: 16px;
  color: var(--p-text-color);
  line-height: 23px;
}

.preventive__heading {
  color: var(--title-text-color);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}

.preventive__heading span {
  color: #5851db;
}

/*for donate page*/
.donate__heading {
  font-size: 22px;
  color: #5851db;
  font-weight: 500;
  margin-bottom: 20px;
}

.donate__info {
  margin-bottom: 30px;
}

.donate__info__title {
  color: var(--title-text-color);
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
}

.donate__info__text {
  color: var(--p-text-color);
  margin-bottom: 40px;
}

.donate__info .button {
  background-color: #5851db;
  color: white;
}

.donate__info .button:hover {
  background-color: #1e1d21;
}

.donate__info a {
  text-decoration: none;
}

.donate-for-mobile {
  display: none;
}

.notification {
  height: 60px;
  background-color: #5851db;
  position: relative;
  padding: 10px 30px;
  top: 7px;
  margin-left: 240px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.notification__text {
  color: white;
  line-height: 40px;
}

.notification__wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}

.notification #timer {
  color: white;
  line-height: 40px;
  margin-left: 30px;
}

.notification__delete {
  color: white;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 8px;
  cursor: pointer;
}

.info_tag {
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  color: #5851db;
  border: 2px solid #5851db;
  display: inline-block;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

[class*="Modal"] {
  background-color: rgba(0, 0, 0, 0.79);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  padding: 30px;
  display: none;
}

.modal {
  height: auto;
  padding-top: 30px !important;
  position: relative;
  max-width: 600px;
  margin: auto;
}

.modal button {
  background-color: transparent;
  border: 0;
  color: var(--title-text-color);
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  background-color: var(--bg-color);
  border-radius: 50%;
  cursor: pointer;
}

.modal p {
  color: var(--p-text-color);
  font-size: 14px;
}

.modal__title:nth-of-type(2) {
  margin-top: 20px;
}

.about__title {
  color: var(--title-text-color);
}

.about .thank {
  margin-top: 26px;
  color: var(--title-text-color);
  font-size: 16px;
}

.about .thank a {
  color: var(--blue-color);
}

.idCard-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(180px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 30px;
  margin-top: 20px;
}

.idCard h3,
.idCard p {
  text-align: center;
}

.idCard__photo {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  display: block;
  margin: auto;
  margin-bottom: 16px;
}

.idCard__name {
  color: var(--blue-color);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.idCard__tag {
  color: var(--title-text-color);
  font-size: 16px;
  margin-bottom: 10px;
}

.idCard__work {
  color: var(--p-text-color);
  font-size: 16px;
}

.idCard .sm-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.idCard svg {
  display: block;
  margin: 10px 10px 0 10px;
}

.idCard svg.twitter {
  width: 22px;
  margin-top: 11.5px;
}

.idCard svg.twitter path {
  fill: #3590ff;
}

.idCard svg.instagram {
  width: 26px;
}

.idCard svg.instagram path {
  fill: #e1306c;
}

.whatsapp-share-button {
  padding-top: 5px;
}

.tribute {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tribute.hide {
  display: none;
}

.tribute__image-container {
  max-width: 600px;
  margin: 0 auto;
}

.tribute__image {
  width: 100%;
  border-radius: 15px;
}

.tribute__close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  color: red;
  border-radius: 5px;
  border: 0;
  outline: 0;
  font-size: 30px;
  cursor: pointer;
}

.tribute__credit {
  color: #5851db;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.tribute__credit span {
  color: #e1306c;
}

#global-data,
#global-demographics,
#international-country-data {
  display: none;
}

.note {
  color: var(--p-text-color);
  margin-top: 20px;
}

.events-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(320px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 30px;
}

.eventCard {
  position: relative;
  height: 100%;
  // display: -ms-grid;
  // display: grid;
  // grid-template-rows: ;
  width: 100%;
}

.eventCard__title {
  font-size: 20px;
  color: var(--title-text-color);
  font-size: 18px;
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  width: 100% !important;
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: calc(100% - 30px);
}

.eventCard__text {
  color: var(--title-text-color);
  font-size: 13px;
  margin-top: 10px;
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
}

.eventCard__text.link {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.eventCard__text.link::after {
  content: "";
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.eventCard__text.link a {
  white-space: nowrap;
  display: inline-block;
  margin-left: 5px;
}

.eventCard__text span {
  font-weight: 500;
  color: var(--blue-color);
  display: inline-block;
  margin-left: 5px;
}

.eventCard__text span.innerLink {
  margin: 5px 0;
}

.eventCard__details {
  color: var(--p-text-color);
}

.eventCard__calendar {
  text-decoration: none;
  color: #fff;
  padding-top: 12px;
  font-size: 14px;
  text-align: center;
  background-color: var(--blue-color);
  border-radius: 7px;
}

.share__jobs-btn {
  background-color: transparent !important;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: var(--blue-color);
  border: 2px solid var(--blue-color);
  border-radius: 7px;
  font-weight: 500;
  font-size: 14px;
  user-select: none;
}

.eventCard__calendar img {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.eventCard__image {
  width: 72px;
  height: 72px;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 5px;
}

.eventCard__firstDiv {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 72px calc(100% - 90px);
  grid-template-columns: 72px calc(100% - 90px);
  grid-gap: 20px;
}

.eventCard__firstDiv img {
  background-color: var(--jobsquare_image_bg);
  border-radius: 5px;
  padding: 5px;
  border: 1px solid rgba(38, 37, 41, 0.815);
}

.eventCard__firstTextBox {
  width: 100%;
}

.eventCard__firstDiv .eventCard__text {
  margin-top: 0;
}

.eventCard__firstDiv .eventCard__text:first-of-type {
  margin-bottom: 5px;
}

.eventCard__firstDiv .eventCard__title {
  margin-bottom: 5px;
}

.eventCard__secondDiv {
  margin-top: 10px;
}

.eventCard__thirdDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
  padding-top: 10px;
}

.eventCard__locationText {
  font-family: Poppins;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #666666;
}

.eventCard__location {
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #aaaaaa;
  display: -webkit-box;
  display: -ms-flexbox;
  // display: flex;
  // -webkit-box-orient: horizontal;
  // -webkit-box-direction: normal;
  //     -ms-flex-direction: row;
  //         flex-direction: row;
  // -ms-flex-wrap: wrap;
  //     flex-wrap: wrap;
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eventCard__location span {
  padding-right: 15px;
}

.eventCard__location span:after {
  content: ".";
}

.jobFilterModal label {
  display: block;
}

.jobFilterModal .modal {
  width: 250px;
}

.jobFilterModal .modal button.submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 10px;
  background-color: var(--blue-color);
  color: white;
  position: static;
  border-radius: 5px;
  font-size: 15px;
  font-weight: normal;
}

.jobFilterModal .modal button.submit:hover {
  background-color: var(--bg-color);
  color: white;
}

.jobFilterModal input,
.jobFilterModal select {
  border-radius: 2px;
  height: 34px;
  border: 0;
  margin: 5px 0 15px 0;
  width: 100%;
}

.jobsquare__logo {
  margin-bottom: -13px;
  margin-left: 10px;
}

.jobsquare__logo text {
  fill: var(--title-text-color);
}

.noresults {
  width: 90%;
  max-width: 400px;
  margin: auto;
}

#buttonDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 20px 0;
}

.tag_button {
  color: var(--title-text-color);
  cursor: pointer;
  font-weight: 600;
  word-break: keep-all;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  border-radius: 15px;
}

.tag_button:not(:last-child) {
  margin-right: 10px;
}

.tag_button.active {
  background-color: var(--blue-color);
  color: white;
}

.banner_div {
  background-color: #5851db;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  // position: fixed;
  // top: 0;
  // left: 0;
  z-index: 10;
}

.banner_div_text {
  color: white;
  padding-left: 8px;
  text-decoration: none;
}

.banner_div a {
  color: white;
  height: 100%;
  max-width: 60%;
  font-weight: 300;
  font-size: 13px;
  text-align: center;
}

@media (max-width: 400px) {
  .banner_div a {
    font-size: 11px;
  }
}

.search-stats-states,
.search-stats-countries {
  font-family: "Poppins", sans-serif;
  padding: 0 14px;
  outline: none;
  border: 0;
  font-size: 14px;
  border-radius: 7px;
  background: var(--bg-color);
  color: #aaa;
}

#search-stats::-webkit-search-cancel-button {
  background: #000;
  background-image: url("https://img.icons8.com/all/500/search--v1.png");
}

.search-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--bg-color);
  border-radius: 7px;
  position: relative;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-item-search {
  width: 100%;
  font-weight: 500;
}

.flex-item-span {
  background: transparent;
  color: #fff;
  position: absolute;
  right: 0;
  z-index: 1;
  text-align: center;
}

.cleanBtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.go-to-top-button {
  position: fixed;
  -webkit-box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.16);
  box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.16);
  height: 60px;
  width: 60px;
  z-index: 3;
  margin: auto;
  bottom: 20px;
  right: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 30px;
  background-color: var(--layer-color);
  cursor: pointer;
}

.go-to-top-button:hover {
  background-color: var(--bg-color);
}

.go-to-top-button img {
  position: absolute;
  height: 40px;
  width: 40px;
  z-index: 3;
  margin: auto;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
}

.load-more-btn {
  outline: none;
  border: none;
  color: white;
  padding: 10px 20px;
  background-color: var(--blue-color);
  margin-top: 30px;
  border: 2px solid var(--blue-color);
  border-radius: 4px;
  cursor: pointer;
}

.load-more-btn:hover {
  background-color: var(--bg-color);
  color: var(--blue-color);
}

.state-button-div {
  height: 20px;
  width: 20px;
  background-color: var(--li-hover);
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  text-align: center;
}

.state-button {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
}

.state-button:hover {
  -webkit-transform: rotate(450deg);
  transform: rotate(450deg);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.JobsLoader {
  height: 100px;
}

.state-graphs {
  margin-top: 30px;
  color: #aaa;
}

.state-graph__name u {
  cursor: pointer;
}

.chart-type__toggle {
  display: grid;
  text-align: center;
  grid-template-columns: repeat(4, 1fr);
}

.chart-type__toggle div {
  cursor: pointer;
  color: #aaa;
  user-select: none;
}

.chart-type__toggle div:nth-child(1).selected {
  color: #3590ff;
}
.chart-type__toggle div:nth-child(2).selected {
  color: #5851db;
}
.chart-type__toggle div:nth-child(3).selected {
  color: #4ccf76;
}
.chart-type__toggle div:nth-child(4).selected {
  color: #e1306c;
}

@media only screen and (max-width: 1024px) {
  .detail-bar__title,
  .homepageEvents__title,
  .line-chart__title,
  .state__title,
  .state-graph__name,
  .modal__title {
    font-size: 16px;
  }
  .map__iframe-container,
  .stat,
  .chart__block,
  .state-graphs,
  .state,
  .location,
  .share-module,
  .tips,
  .donate__info,
  .modal,
  .idCard,
  .eventCard {
    padding: 14px;
  }
  h4 {
    font-size: 14px;
  }
  .map-stat-container {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
  p {
    font-size: 14px;
  }
  .stats-container {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 15px;
  }
  .stat {
    padding: 15px;
  }
  .stat__figure {
    font-size: 28px;
  }
  .stat__block__figure {
    font-size: 14px;
  }
  .stat__daily-rise {
    margin-top: 10px;
    margin-left: 6px;
  }
  .events-container {
    grid-gap: 15px !important;
    -ms-grid-columns: (minmax(250px, 1fr)) [auto-fill];
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .homepageEvents {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .hr-events-container {
    overflow-x: scroll;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-bottom: 30px;
    width: auto;
  }
  .hr-events-container .card-contain {
    scroll-snap-align: start;
  }
  .hr-events-container .card-contain:last-child .eventCard {
    margin-right: 0;
  }
  .hr-events-container .eventCard {
    max-width: 300px;
    margin-right: 15px;
    height: auto;
  }
  .detail-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .main-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
  }
  .sidebar {
    left: -240px;
  }
  .sidebar .button {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    width: 100%;
  }
  .feedback .button__share {
    display: block;
    margin-top: 20px;
  }
  .topBar .button__share {
    display: block;
    position: absolute;
    right: 15px;
    top: 4px;
  }
  .topBar .button__share svg {
    height: 26px;
    width: 26px;
    margin-left: 7px;
    margin-top: 7px;
    fill: var(--title-text-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .menu-button:hover {
    background-color: var(--sidebar-color);
  }
  .menu-button:hover svg {
    fill: var(--title-text-color);
  }
  .topBar {
    top: 0;
  }
  .map {
    height: 400px;
  }
  .map__iframe-container {
    padding: 0;
  }
  #india-last-update {
    position: static;
  }
  .detail-bar__last-update {
    margin-top: 10px;
  }
  .detail-bar__title {
    margin-right: 0;
  }
  .detail-bar .main-toggle {
    top: -7px;
  }
  .detail-bar .main-toggle__tag {
    margin-right: 6px;
  }
  .detail-bar .main-toggle__el {
    width: 88px;
    left: 0;
  }
  .detail-bar .main-toggle__el::after {
    top: 7.4px;
    right: 5px;
    height: 24px;
    width: 24px;
  }
  .line-chart {
    margin: 15px 0;
    padding: 0;
  }
  .line-chart .chart__title {
    margin-bottom: 70px;
  }
  .line-chart .chart .main-toggle {
    top: 48px;
  }
  .chart__graph-container {
    height: 400px;
  }
  .chart__block {
    margin-top: 15px;
  }
  footer {
    height: auto;
    line-height: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 0;
  }
  footer p:first-child {
    margin-bottom: 15px;
  }
  .location {
    height: 230px;
  }
  .location__message {
    font-size: 14px;
  }
  .share-module-container {
    gap: 15px;
    -ms-grid-rows: none;
    grid-template-rows: none;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .share-module {
    min-height: 112px;
  }
  .share-module__title {
    font-size: 14px;
  }
  .share-module .button {
    font-size: 14px;
    display: block;
  }
  .state,
  .state-graphs {
    margin-top: 15px;
  }
  .state th {
    font-weight: 500;
  }
  .state tr {
    -ms-grid-columns: 1.7fr 1fr 1fr 0.8fr;
    grid-template-columns: 1.7fr 1fr 1fr 0.8fr;
  }
  .state tr th,
  .state tr td {
    font-size: 14px;
    padding: 5px 0;
  }
  .state tr td:first-child,
  .state tr th:first-child {
    padding: 5px 0;
    position: relative;
  }
  .state-button-div {
    background-color: transparent;
    width: auto;
    margin: 0;
  }
  .donate-for-mobile {
    display: none;
    text-decoration: none;
    margin-top: 20px;
    background-color: #5851db;
    color: white;
  }
  .notification {
    height: 70px;
    margin-right: 0;
    padding: 10px 15px;
    margin-left: 0;
  }
  .notification__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .notification #timer,
  .notification__text {
    line-height: normal;
    margin: 0;
  }
  .notification__delete {
    top: 11px;
  }
  #buttonDiv {
    overflow-x: scroll;
  }
  .tag_button:not(:last-child) {
    margin-right: 4px !important;
  }
  .state-page {
    height: calc(100% - 100px) !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .button {
    padding: 10px;
  }
  .button.twitter p {
    font-size: 12px;
  }
  .button.twitter svg {
    width: 20px;
    margin-right: 20px;
    position: absolute;
    top: 14px;
    left: 13px;
  }
  .idCard-container {
    grid-gap: 15px;
    -ms-grid-columns: (minmax(calc(50% - 7.5px), 1fr)) [auto-fill];
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 7.5px), 1fr));
  }
  .idCard__name {
    font-size: 14px;
  }
  .idCard__name.small-name {
    font-size: 12px;
  }
  .idCard__tag {
    font-size: 14px;
  }
  .idCard__work {
    font-size: 14px;
  }
  .idCard__photo {
    height: 80px;
    width: 80px;
    border-radius: 40px;
  }
  .about .thank {
    font-size: 14px;
  }
  .hr-events-container {
    -ms-grid-columns: (minmax(270px, 1fr)) [auto-fill];
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
  .stat-inliner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .stat__daily-rise {
    margin-top: 0;
    margin-left: 0;
  }
  .state-page {
    height: calc(100% - 100px) !important;
    width: 100% !important;
  }
  .share-module-container {
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    -ms-grid-columns: none;
    grid-template-columns: none;
  }
  /* code added here to have the navbarbelow */
  .mobilenavbar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    height: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--bg-color);
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .mobilenavbar .active p {
    color: #5650d8;
  }
  .mobilenavbar svg {
    margin: auto;
  }
  .mobilenavbar .active p {
    color: #5650d8;
  }
  .mobilenavbar p {
    height: 14px;
    font-size: 12px;
    line-height: 1.17;
    color: #707070;
  }
  .mobilenavbar .active svg path {
    fill: #5650d8;
  }
  .cls-1 {
    fill: #7f7f81;
  }
  .go-to-top-button {
    bottom: 75px;
  }
  footer {
    margin-bottom: 50px;
  }
  /* code to have the navbarbelow ends here*/
}
/*# sourceMappingURL=new-v.css.map */
